export function loadGtm(gtmId='G-C0TE8PE1Q7') {
	window.dataLayer = window.dataLayer || [];
	function gtag() {
		window.dataLayer.push(arguments);
	}
	gtag('js', new Date());
	gtag('config', gtmId);

	((w, d, s, l) => {
		w[l] = w[l] || [];
		w[l].push({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js'
		});
		const f = d.getElementsByTagName(s)[0];
		const j = d.createElement(s);
		j.async = true;
		j.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
		f.parentNode.insertBefore(j, f);
	})(window, document, 'script', 'dataLayer');
}
