import Vue from 'vue';
import App from './App.vue';
import Toastr from 'toastr';
import './styles/entry.js';
import VueScrollTo from 'vue-scrollto';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import router from './router';
import { buyout } from 'js-mp';
import { config } from '@/config';
import Vuetify from 'vuetify/lib';
import VueI18n from 'vue-i18n';
import { extend } from 'lodash';
import { overrides } from './language_overrides';
import { version } from '../package.json';
import buyout_store, { VUEX_BUYOUT } from 'js-mp/src/vue/store/buyout/index';
import Vuex from 'vuex';

Vue.config.productionTip = false;

Toastr.options = {
	position: 'top right',
	showDuration: 1000,
	hideDuration: 1000,
	timeOut: 2500,
	delay: 0,
	closeButton: true,
	progressBar: false
};

Vue.use(Vuex);
Vue.use(Vuetify);
Vue.use(VueI18n);
Vue.use(VueScrollTo);

if (
	process.env.NODE_ENV === 'production' ||
	process.env.NODE_ENV === 'staging'
) {
	Sentry.init({
		dsn: process.env.VUE_APP_SENTRY_DSN,
		release: 'istores_sk@' + version,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			}),
		],
		tracesSampleRate: process.env.NODE_ENV === 'staging' ? 1.0 : 0.65,
		environment: process.env.NODE_ENV
	});
}

// override default config
buyout.config = extend(buyout.config, config);

const NS = VUEX_BUYOUT.NAMESPACE;
let options = {
	modules: {}
};

options.modules[NS] = buyout_store;
export const store = new Vuex.Store(options);

// override default language settings
const { vuetify, i18n } = buyout.plugins;

i18n.locale = config.language;

for (const lang in overrides) {
	i18n.mergeLocaleMessage(lang, overrides[lang]);
}

buyout.utils.apiAxios.defaults.headers = {
	...buyout.utils.apiAxios.defaults.headers,
	'Accept-Language': config.language
};

buyout.store.state.contractForm.customer_data.phone_number =
	config.international_phone_prefix;
buyout.store.state.contractForm.customer_data.bank_code =
	config.default_bank_code;

new Vue({
	store,
	router,
	vuetify,
	i18n,
	render: h => h(App)
}).$mount('#app');
